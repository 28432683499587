.search-input {
    padding: 5px 14px !important;
    min-height: 44px;
    height: auto;
    // border: 1px solid rgba(209, 213, 219, 1);
    // border-radius: .375rem;
}

input {
    --tw-border-opacity: 1;
    -webkit-appearance: none;
    appearance: none;
    border-color: rgba(209, 213, 219, 1);
    border-radius: .375rem;
    border-width: 1px;
    padding: .5rem .75rem;
    transition-duration: .15s;
    width: 100%;

    &:focus {
        border: 2px solid #0c4a6e
    }
    &:hover {
        border: 2px solid #0c4a6e
    }
}

.search-input[type="number"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}