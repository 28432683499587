.table-default thead {
    background-color: #f5f8fa;
    color: var(--themeColor);
}

.table-default thead tr th {
    color: rgba(107, 114, 128, 1);
    font-size: .85rem;
    letter-spacing: .05em;
    line-height: 1rem;
    padding: .75rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;

    &:first-child {
        text-align: start;
    }
}

.table-default tbody tr {
    border-bottom: 0.8px solid #e2e9e9;
}

.table-default td {
    padding: 1rem 1.5rem;
    word-wrap: break-word;
    text-align: center;
    font-size: .95rem;
    font-weight: 400;
    color: var(--themeColor);

    &:first-child {
        text-align: start;
    }

}