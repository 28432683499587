.no-result-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    margin-top: 80px;
  
    @media screen and (min-width: 300px) and (max-width: 750px) {
      margin-top: 50px;
    }
  }
  
  .no-result-found-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  
    @media screen and (min-width: 300px) and (max-width: 750px) {
      gap: 0px;
    }
  
    h5 {
      font-weight: 600;
      font-size: 32px;
      line-height: 16px;
      color: #000;
  
      @media screen and (min-width: 300px) and (max-width: 750px) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  
    p {
      color: #000;
      font-weight: 400;
      font-size: 24px;
      line-height: 16px;
      font-family: "Century Gothic";
      margin-bottom: 80px;
  
      @media screen and (min-width: 300px) and (max-width: 750px) {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        font-family: "Poppins";
        margin-top: -3px;
        margin-bottom: 0px;
      }
    }
  }