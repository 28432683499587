.playPauseButton {
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: transparent;
    backdrop-filter: blur(4px);
    // background: white;
    top: 45%;
    left: 40%;
    filter: contrast(0.6);
}

.fullScreenButton {
    position: absolute;
    display: block !important;
    right: 5%;
    top: 4%;
}

.videoContainer:hover {
    .playPauseButton {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // .fullScreenButton {
    //     display: block !important;
    // }
}

.videoContainer::after {
    content: "\f106";
    position: absolute;
    left: 0;
    color: #221638;
    top: -20px;
    line-height: 1;
    z-index: -1;
    right: 0;
    opacity: .05;
    font-family: Flaticon;
    font-size: 150px;
}

.table-center {
    text-align: center !important;
}

.ad-main-container {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 20px 0px;
    gap: 10px;

    @media screen and (min-width: 300px) and (max-width: 750px) {
        padding: 60px 0px;
        display: -webkit-flex
    }

    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--themeColor);
        margin-bottom: 30px;

        @media screen and (min-width: 300px) and (max-width: 550px) {
            font-size: 15px;
            margin-bottom: 26px;
            text-align: center;
        }
    }
}

.ad-container {
    box-shadow: 0px 6px 12px #0000001a;
    border-radius: 16px;
    animation-name: grow-box;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    width: 547px;
    height: 455px;
    background: #fff;
    overflow: auto;

    @media screen and (min-width: 300px) and (max-width: 550px) {
        width: auto;
        height: auto;
    }
}

.ad-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    color: #01a8ac;
    padding: 15px;
    border-bottom: 1px solid #f4f6f6;
    width: 100%;
  
    @media screen and (min-width: 300px) and (max-width: 550px) {
      padding: 21px;
    }
    .close-button {
      cursor: pointer;
    }
  }