.drawer-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ant-form-item-required {
    flex-direction: row-reverse !important;
    gap: 2px;
    color: var(--themeColor) !important;
    font-weight: 500;
    font-size: 14px !important;

    &::after {
        display: none !important;
    }
}

.ant-form-item-label>label {
    color: var(--themeColor) !important;
    font-weight: 500;
    font-size: 14px !important;
}

.ant-form-item-explain {
    padding-bottom: 12px;
}

textarea {
    resize: none !important;
}

.ant-btn-primary {
    background-color: var(--themeColor) !important;
    color: #fff !important;
}

.ant-drawer-title {
    color: var(--themeColor) !important;
    font-weight: bold !important;
    font-size: 20px !important;
}

.ant-drawer-header-title {
    flex-direction: row-reverse !important;
}

.countryDropdown {
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 10px;
    width: 100%;
    font-size: 14px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    text-align: left;

    /* background-image: url("../../public/images/dropdownTheme.svg"); */
    background-repeat: no-repeat;
    background-position: calc(100% - 20px);
    background-size: 25px;
}