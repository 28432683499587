.edit-promotion-container {
  display: flex;
  flex-direction: column;
  float: right;
  font-size: 14px;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
  position: absolute;
  background: #ffffff;
  border-radius: 4px;
  // left: 430px;
  // top: 185px;
  right: 20%;
  padding: 4px 23px;
  z-index: 1;
  margin-top: 0%;

  .edit-category-button {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 5px 6px;
    cursor: pointer;

    &:hover {
      background-color: #f9fbfb;
      color: var(--themeColor);
      font-weight: 600;
      border-radius: 3px;
    }
  }

  .edit-category-wrapper {
    cursor: pointer;

    &:hover {
      color: var(--themeColor);
      font-weight: 600;
    }
  }
}