.css-t1kbgr-control {
    width: 100%;
}

.css-b62m3t-container {
    width: 100%;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-qav5hl-singleValue {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--themeColor) !important;
}

.css-q81oy9-control {
    width: 100%;
}

.css-zaip1u-menu {
    border-radius: 12px;
}

.css-18gskix-option {
    color: var(--themeColor) !important;
    font-weight: 500;
}

.css-9gfo7z-option {
    color: #010101;
}