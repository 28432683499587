.ohno-container {
  box-shadow: 0px 6px 12px #0000001a;
  border-radius: 10px;
  animation-name: grow-box;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  width: 280px;
  height: 330px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 125px;
  margin-top: 125px;
}

.ohno-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.ohno-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #ef4444;
  margin-top: -10px;
}

.ohno-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7c7c7c;
}

.ohno-btn {
  background: #ef4444;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  padding: 8px 16px;
  color: #fff;
}

.ohno-icon {
  color: #ef4444;

  svg {
    font-size: 24px;
  }
}