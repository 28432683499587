.pagination-paper {
    border-radius: .25rem;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 600;
    height: 2rem;
    justify-content: center;
    line-height: 1.25rem;
    padding-left: .25rem;
    padding-right: .25rem;
    -webkit-user-select: none;
    user-select: none;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(107, 114, 128, 1);

    &:hover {
        background-color: #f3f4f6;
    }

    &:disabled {
        background: none;
        cursor: no-drop;
    }
}

.pagination-paper-disabled {
    &:hover {
        background-color: #fff !important;
        cursor: no-drop;
    }
}

.pagination-paper-inactive {
    color: rgba(12, 74, 110, 1);
    background-color: rgba(240, 249, 255, 1);

    &:hover {
        color: rgba(12, 74, 110, 1);
        background-color: rgba(240, 249, 255, 1);
    }
}

.list-disabled:disabled {
    cursor: no-drop;
}