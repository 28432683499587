.denied-text {
  color: var(--themeColor);
  font-family: "Century Gothic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: 8px;
}

.denied-container {
  box-shadow: 0px 6px 12px #0000001a;
  border-radius: 16px;
  animation-name: grow-box;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  width: 543px;
  height: 382px;
  background: #fff;
  padding: 24px;

  @media screen and (min-width: 300px) and (max-width: 550px) {
    width: auto;
  }
}

.modal-denied-image-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--themeColor);
  padding-bottom: 20px;
  border-bottom: 1px solid #f4f6f6;

  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: var(--themeColor);
    margin-bottom: 0;
    font-family: "Poppins";
  }

  .close-button {
    font-size: 19px;
    cursor: pointer;
  }
}

.text-area-denied .textarea {
  margin-top: 29px;
  margin-bottom: 40px;
  height: 149px;
  padding-top: 8px;
}

.denied-btns {
  justify-content: end;
  display: flex;
  gap: 25px;
}

.denied-btn {
  background-color: #fff;
  padding: 8px 18px;
  color: var(--themeColor);
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 33px;
  border: 1px solid var(--primary-teal-500-main, --themeColor);
  background: var(--shades-0, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    border: 2.5px solid #000;
  }
}

.denied-changes {
  background-color: var(--themeColor);
  padding: 8px 18px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(117.16deg,
        #b2d236 14.24%,
        #2bb24b 52.69%,
        #000 93.83%);
  }

  @media screen and (min-width: 300px) and (max-width: 550px) {
    font-size: 12px;
  }
}

.ant-modal-content {
  background: none !important;
  box-shadow: none !important;
  border-radius: none !important;

  .ant-modal-close {
    display: none;
  }
}

.ant-modal-body {
  padding: 0;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-content {
  background: none !important;
  box-shadow: none !important;
  border-radius: none;
}

.text-area-denied .textarea {
  margin-top: 29px;
  margin-bottom: 40px;
  height: 149px;
  padding-top: 8px;
}

.text-area {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  textarea {
    resize: none;
    height: 50px;
    color: #c0cece;
    padding: 0.4rem;
    font-family: 'Century Gothic';

    &:hover {
      border: 1px solid var(--themeColor);
      fill: #000;
      transition: 0.5s;
      color: #000;
      border-image-slice: 1;
    }

    &::placeholder {
      color: #c0cece !important;
    }

    &:disabled {
      background-color: #c0cece6e;
      cursor: no-drop;
      pointer-events: none;
    }
  }

  textarea {
    color: var(--themeColor);
    font-size: 16px;
    padding-left: 1rem;
    // padding-right: 40px;

    &:disabled {
      color: #8ca5a5;
    }

    @media screen and (min-width: 550px) and (max-width: 1050px) {
      font-size: 12px;
    }

  }
}

textarea[type="text"],
textarea[type="number"] {
  color: var(--themeColor) !important;

  &::placeholder {
    color: #c0cece !important;
  }
}

.textarea {
  border-radius: 17px;
  background: #ffffff;
  border: 1px solid #c0cece;
  outline: none;
  transition: 0.5s;
  color: var(--themeColor);
}

textarea[type="text"],
textarea[type="number"],
select {
  color: var(--themeColor);
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
textarea[type="number"] {
  -moz-appearance: textfield;
}

textarea[type="text"]:focus,
textarea[type="number"]:focus {
  border: 1px solid var(--themeColor);
  outline: none !important;
}