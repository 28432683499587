.login-form {
    display: flex;
    flex-direction: column;
    background-position-y: bottom;
    background-repeat: no-repeat;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: #e2e9e9;
}

.login-page {
    width: 100%;
    height: 100%;
    // background: #F8EDFA;
    gap: 0px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.login-cover {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.login-image {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.login-page-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.login-page-cover-container {
    display: flex;
    gap: 150px;
    padding: 5rem 8rem 3rem 4rem;
    width: 100%;
}

.login-logo {
    // position: absolute;
    // top: -150px;
}

.login-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 33px;
    padding: 65px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 534px;
    height: 65%;
    margin: 70px 0px;

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        width: 385px;
        padding: 31px;
    }

    @media screen and (min-width: 300px) and (max-width: 750px) {
        min-width: 365px;
        padding: 16px;
        width: auto;
    }
}

.admin-panel {
    background: var(--themeColor);
    width: 148px;
    border-radius: 38px;
    text-align: center;
    padding: 6px 13px;

    span {
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        font-family: "Poppins";
        font-weight: 700;
        color: #ffffff;
    }
}

.visueats-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
}

.login-container {
    margin-top: 20px;
    margin-bottom: 25px;

    div {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.02em;
        color: var(--themeColor);
        margin-bottom: 5px;
    }

    p {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        color: var(--themeColor);
        margin-top: 16px;
    }
}

.email-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 8px;
    position: relative;

    .input-email-container {
        display: flex;
        align-items: center;
    }
}

.otp-input {
    text-align: center;
    gap: 25px;
}