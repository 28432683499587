.side-nav {
  width: 250px;
  height: auto;
  transition: width 0.3s;
  height: 100vh;
  background: #fff;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  border: 1px solid #e5e7eb;
  flex-direction: column;
  flex-shrink: 0;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  z-index: 10;
  color: var(--themeColor);
  font-weight: 700;
  position: sticky;
  top: 0;
}

.side-nav a {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  gap: .5rem;

  &:hover {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 500;
    background: rgba(229, 231, 235, 1);
    direction: ltr;
    cursor: pointer;
    z-index: 1;
    transition: color .2s;
    border-radius: 8px;
  }

  span {
    transition: opacity 1s;
  }

}

.selectednav {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  background: rgb(187 189 193 / 54%);
  direction: ltr;
  cursor: pointer;
  z-index: 1;
  transition: color .2s;
  border-radius: 8px;
}

.side-nav li span {
  margin-left: 10px;
  column-gap: .5rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  white-space: nowrap;
  border-radius: .375rem;
}

.navbar-logo {
  display: flex;
}

.nav-buttons-title {
  font-weight: 600;
  margin-bottom: .5rem;
  margin-top: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  text-transform: uppercase;
  color: #6b7280;
  display: flex;
  font-size: 14px;
}

.side-nav-list {
  padding: 1rem;
}