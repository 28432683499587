.reserv-status-container {
  display: flex;
  flex-direction: column;
  float: right;
  font-size: 14px;
  border: 1px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
  position: absolute;
  right: -5px;
  margin-top: 22px;
  background: #ffffff;
  border-radius: 4px;
  padding: 8px 10px;
  gap: 2px;

  @media screen and (min-width: 300px) and (max-width: 750px) {
    padding: 0;
    margin-top: 12px;
    margin-left: -55px;
  }
}

.status-btn {
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  width: 114px;
  cursor: pointer;

  @media screen and (min-width: 300px) and (max-width: 750px) {
    padding: 0px 0px;
    width: 54px;
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #fff;

    @media screen and (min-width: 300px) and (max-width: 750px) {
      font-size: 8px;
    }
  }
}

.Accepted-bg {
  background: #10b981;

  &:hover {
    background: #6ee7b7;
  }
}

.Rejected-bg {
  background: #b91c1c;

  &:hover {
    background: #f87171;
  }
}