.timepicker {
  background: #ffffff;
  border: 1px solid #c0cece;
  // border-radius: 33px;
  width: 12rem;
  outline: none;
  transition: 0.5s;
  color: var(--themeColor);
  padding: 11px 14px;

  &:hover {
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
    fill: var(--themeColor);
    transition: 0.5s;
    color: var(--themeColor) !important;
    border-image-slice: 1;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    display: none !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    display: none !important;
  }
}

.ant-picker-now-btn {
  display: none;
}

.ant-picker-ok {
  cursor: pointer;
}

.ant-btn-sm {
  background: var(--themeColor);
  border: none;
  width: 35px;
  height: 24px;
  background: #01a8ac;
  border: 1px solid #01a8ac;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 33px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(95.39deg,
        #b2d236 9.4%,
        #2bb24b 51.68%,
        #01a8ac 96.92%);
    border: none;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    font-family: "Poppins";
  }
}

.ant-picker-time-panel-cell-inner {
  color: #01a8ac;
}

.ant-picker-panel-container {
  margin-top: -3px;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  isolation: isolate;
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
}

.ant-picker-input {
  font-family: "Century Gothic";
  margin-left: 6px;
  color: var(--themeColor);

  &::placeholder {
    color: #c0cece;
  }
}

.ant-picker-suffix {
  display: none;
}

.ant-picker-clear {
  display: none;
}