.header-container {
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: auto;
    z-index: 999;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1dbdb;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f8fa;

    .logo-container {
        .logo {
            width: 33px;
            height: 33px;

            @media screen and (min-width: 350px) and (max-width: 550px) {
                width: 44px;
                height: 44px;
            }
        }
    }

    .header-flex {
        padding-left: 35px;
        padding-right: 35px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 350px) and (max-width: 550px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .option-container {
        width: 158px;
        display: flex;
        display: -webkit-flex;
        justify-content: end;
        align-items: center;

        @media screen and (min-width: 350px) and (max-width: 550px) {
            width: 150px;
        }

        .image-input {
            .profile-image {
                // background: url(https://firebasestorage.googleapis.com/v0/b/dev-visueats.appspot.com/o/userProfile%2F611138.png?alt=media&token=e8c971ef-7198-4dae-93fa-c54e7dd6f7b3);
                border-radius: 50%;
                width: 33px;
                height: 33px;
            }
        }

        .get-help {
            display: flex;
            display: -webkit-flex;
            position: relative;

            .get-help-icon {
                display: -webkit-flex;
            }

            .get-help-icon svg {
                font-size: 39px;
                display: flex;
                display: -webkit-flex;
                color: #000;
                // &:hover {
                //   color: #01a8ac;
                // }
            }
        }

        .get-help-header {
            position: absolute;
            bottom: -34px;
            left: -38px;
            color: #000;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            border: 1px solid #000;
            // background: linear-gradient(white, white) padding-box,
            //   linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%)
            //     border-box !important;
            background: #fff;
            padding: 6px 16px 6px 16px;
            border-radius: 18px;
            width: 105px;
            height: 28px;
            display: none;

            &:hover {
                background-color: #fff;
            }
        }

        .get-help-header::after {
            content: "";
            position: absolute;
            top: -3%;
            left: 50%;
            border-top: 1px solid #000;
            border-bottom: 0px solid #fff;
            border-left: 0px solid #fff;
            border-right: 1px solid #000;
            transform: rotate(-45deg);
            width: 10px;
            height: 10px;
            margin-top: -4px;
            background-color: #fff;
        }

        .get-help:hover .get-help-header {
            display: block;
        }

        .border {
            height: 28px;
            border-right: 1px solid #e2e9e9;
            text-align: center;
            align-items: center;
        }

        .btn {
            width: 79px;
            height: 38px;
            background: #fff;
            color: #45b8b7;
            border: 1px solid transparent;
            background: linear-gradient(#fff, #fff) padding-box, linear-gradient(95.39deg, #b2d236 9.4%, #2bb24b 51.68%, #01a8ac 96.92%) border-box !important;
            border-radius: 33px;
            font-size: 14px;
            font-weight: 600;

            &:hover {
                border: 3px solid transparent;
                background: linear-gradient(white, white) padding-box,
                  linear-gradient(
                      95.39deg,
                      #b2d236 9.4%,
                      #2bb24b 51.68%,
                      #01a8ac 96.92%
                    )
                    border-box !important;
              }
        }
    }
}
