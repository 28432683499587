.promo_profile-camera {
    position: absolute;
    left: 268px;
    top: 207px;
    background: #f9fbfb;
    border: 1px solid #e2e9e9;
    border-radius: 50%;
    width: 51px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;

    svg {
        color: #000;
        width: 23px;
        height: 21px;
    }

    @media screen and (min-width: 700px) and (max-width: 1200px) {
        left: 156px;
        top: 129px;
    }
}

.promotion-image-container {
    color: #8ca5a5;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0;
    border: 1px dashed #8ca5a5;
    width: 302px;
    height: 222px;
    margin: 20px 0px 20px 0px;
    border-radius: 8px;
    padding-top: 0px;
    cursor: pointer;
    outline: none;
    background: #fff;

    .promotion-image {
        width: 300px;
        height: 220px;
        border-radius: 8px;
        object-fit: cover;
    }
}